var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isCurrent ? _c('div', [!_vm.editable && !_vm.creating && !_vm.isNewAnswer ? _c('div', [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.EnableEdit(true);
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "pencil-lock-outline",
      "size": 18
    }
  }), _vm._v(" Edit ")], 1)], 1) : _c('div', [_c('b-button', {
    directives: [{
      name: "debouncing",
      rawName: "v-debouncing",
      value: 1000,
      expression: "1000"
    }],
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSubmit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "content-save",
      "size": 18
    }
  }), _vm._v(" Save ")], 1), _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "refresh",
      "size": 18
    }
  }), _vm._v(" Reset ")], 1), !_vm.creating && !_vm.isNewAnswer ? _c('b-button', {
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "close-circle-outline",
      "size": 18
    }
  }), _vm._v(" Cancel ")], 1) : _vm._e()], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }